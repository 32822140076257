import axios from 'axios';

const HEADERS = {
  'X-User-Email': `${process.env.REACT_APP_USER_EMAIL}`,
  'X-User-Token': `${process.env.REACT_APP_USER_TOKEN}`
};

export const getExchangeRates = async (IdSucursal) => {
  try {
    const API_URL = `/api/exchange_rates.json?branch_id=${IdSucursal}`
    const response = await axios.get(API_URL, { headers: HEADERS });
    console.log('User : ', `${process.env.REACT_APP_USER_TOKEN}`)
    console.log('Email : ', `${process.env.REACT_APP_USER_TOKEN}`)
    console.log('URL : ', API_URL)
    console.log('Response data:', response);
    
    if (!response.data || !response.data.exchange_rates) {
          throw new Error('No exchange rates found in response');
        }
    const rates = response.data.exchange_rates.map(rate => {
    return {
        buy: rate.clp_purchase,
        sell: rate.clp_sell,
        country: rate.currency.country,
        name: rate.currency.name,
        code: rate.currency.code,
        is_active: rate.currency.is_active,
        is_favorite: rate.currency.is_favorite
      };
    });
    return rates;
  } catch (error) {
    console.error('Error fetching exchange rates:', error);
    throw error;
  }
};
