
import React from 'react';
import '../../App.css'
const Loading = ({sucursal}) => {
  return (
<div className='container-fluid d-flex justify-content-center align-items-center vh-100'>
  <div className="row">
    <div className="text-center text-lg loading">
      <h3><span className='mb-1 '>Cargando Divisas... {sucursal}</span> </h3>
      <img 
            src={`${process.env.PUBLIC_URL}/loading.gif`}
            alt='Cargando Divisas ...'>
      </img>
    </div>
  </div>
</div>
  );
};

export default Loading;
