import React from 'react';
import { Link } from 'react-router-dom';
import '../../App.css'
function Home() {
  return (
    <div className='App-Home vh-100' >
      <div className='row'>
      <div className='row'>
            <header className='mb-5 justify-content-between'>
              <h1 className="text-casa text-muted ms-5">
                <img src="/lhex-icono-app.png" alt="LHEX Logo" className="mb-0 logo img-fluid ms-5"/>
                <span className='ms-5'>CASA DE CAMBIO - MONEY EXCHANGE</span>              
              </h1>
            </header> 
        </div>
      </div>
    
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <div className="row justify-content-center">
          <div className="equal-width-buttons d-grid gap-5 my-1">
            <Link to="/2">
              <button className="btn btn-success btn-lg">Divisas Punta Arenas - Mall Espacio Pionero</button>
            </Link>
            <Link to="/3">
              <button className="btn btn-success btn-lg">Divisas Punta Arenas - Zona Franca</button>
            </Link>
            <Link to="/1">
              <button className="btn btn-success btn-lg">Divisas Puerto Natales</button>
            </Link>
          </div>
        </div>
      </div>
  </div>
  );
}

export default Home;
