import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../components/Home/Home'; // Importa el componente Home
import ExchangeRates from '../components/ExchangeRates/ExchangeRates'; // Importa el componente Page

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<ExchangeRates />} />
    </Routes>
  );
};

export default AppRoutes;
