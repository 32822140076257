
import React from 'react';
import '../../App.css'
const ErrorExchangeRates = ({mensaje}) => {
  return (
<div className='container-fluid d-flex justify-content-center align-items-center vh-100'>
  <div className="row">
    <div className="text-center text-lg Errorloading">
        <h3>
          <span className='mb-1 '> Verifique conexion a internet</span> 
          <span className='mb-1 '>Error al cargar divisas motivo :  {mensaje}</span>
        </h3>
    </div>
  </div>
</div>
  );
};

export default ErrorExchangeRates;
