import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getExchangeRates } from '../../services/api'; 
import MainCurrencies from './MainCurrencies';
import OtherCurrencies from './OtherCurrencies';
import Loading from './Loading';
import ErrorExchangeRates from './ErrorExchangeRates';
import './CurrencyTable.css';
import '../../App.css';


function ExchangeRates() {
  const { id } = useParams(); // Obtener el parámetro id desde la URL
  const navigate = useNavigate(); // Hook para redirigir a otra ruta
  const [loading, setLoading] = useState(true); // Estado para mostrar un loader mientras cargan los datos
  const [error, setError] = useState(null); // Estado para manejar errores
  const [favoriteRates, setFavoriteRates] = useState([]);
  const [nonFavoriteRates, setNonFavoriteRates] = useState([]);
  // Validar si el id es correcto (en este caso validamos si es 1, 2 o 3)
  const locationMap = {
    1: 'Puerto Natales',
    2: 'Mall Espacio Pionero',
    3: 'Zona Franca'
  };

  
  useEffect(() => {
    const validIds = ['1', '2', '3'];
    if (!validIds.includes(id)) {
      navigate('/'); // Redirigir a Home si el id no es válido
    }
  // Obtener el nombre del lugar según el id, si no existe un id válido redirigir
    
    // Llamada a la API
    const fetchExchangeRates = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 500));
        const rates = await getExchangeRates(id);
        const favorite = rates.filter(rate => rate.is_favorite);
        const nonFavorite = rates.filter(rate => !rate.is_favorite);
        setFavoriteRates(favorite); // Guardar los datos en el estado
        setNonFavoriteRates(nonFavorite); // Guardar los datos en el estado
        setLoading(false); // Finalizar la carga
      } catch (err) {
        console.error('Error fetching exchange rates:', err);
        setError(err); // Manejar error
        setLoading(false);
      }
    };

    fetchExchangeRates();

    // Configurar intervalo para llamar a la API cada 15 minutos
    const interval = setInterval(fetchExchangeRates, 900000); // 900000 ms = 15 minutos

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [id, navigate]);

  if (loading) {
    return <Loading sucursal={locationMap[id]} /> // Loader mientras cargan los datos
  }

  if (error) {
    return <ErrorExchangeRates mensaje={error.message}/>
  }
  const locationName = locationMap[id];
  return (
    <div className='App'>
      <div className="container-fluid">
        <div className='row'>
            <header className='justify-content-between'>
              <h1 className="titulo text-muted">CASA DE CAMBIO <span className="text-casa">MONEY EXCHANGE</span>
              <img src="/lhex-icono-app.png" alt="LHEX Logo" className="mb-0 logo img-fluid ms-5"/>
              </h1>
            </header> 
        </div>
        <div className='row'>
            <h4 className="mb-1 sub-titulo">VALORES AL DÍA DE DIVISAS PRINCIPALES {locationName.toUpperCase()}</h4>
        </div>

      </div>
      <div className="row">
        <div className="col-md-6">
          <MainCurrencies mainRates={favoriteRates} />
        </div>
        <div className="col-md-6">
          <OtherCurrencies otherRates={nonFavoriteRates} />
        </div>
      </div>
      <div className="row">
        
      </div>
    </div>
  );
}

export default ExchangeRates;
