const  OtherCurrencies = ({otherRates}) => {
return (
        <div className="table-responsive">
        <table  className="table  table-striped table-borderless">
            <thead className="thead-light">
                <tr className="table-secondary">
                    <th scope="col"></th>
                    <th scope="col" className='fs-5'>COMPRADOR</th>
                    <th scope="col"></th>
                    <th scope="col" className='fs-5'>VENDEDOR</th>
                </tr>
            </thead>
            <tbody>
                {otherRates.map((currency) => (
                    <tr key={currency.code}>
                    <td className="text-start">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/${currency.code.toLowerCase()}.png`}
                            alt={`${currency.name} flag`}
                            className="currency-flag"
                            width={'12%'}   
                            height={'10%'}                       
                        />
                        <span className="text-otherCurrency ms-3">{currency.name.toUpperCase()}<br></br></span>
                    </td>
                    <td className="text-otherCurrency-Value text-end">{currency.buy}</td>
                    <td>
                            <span></span>
                        </td>
                    <td className="text-otherCurrency-Value text-end">{currency.sell}</td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>

)
};
export default OtherCurrencies;