//import 'bootstrap/dist/css/bootstrap.min.css';
import './CurrencyTable.css';

const MainCurrencies = ({ mainRates } ) => {
return (
    <div className="table-responsive">
        <table className="table  table-striped table-borderless">
            <thead className="thead-light">
                <tr className='table-secondary'>
                    <th scope="col"></th>
                    <th scope="col" className='fs-5'>COMPRADOR</th>
                    <th scope="col"></th>
                    <th scope="col" className='fs-5'>VENDEDOR</th>
                </tr>
            </thead>
            <tbody>
                {mainRates.map((currency) => (
                    <tr key={currency.code}>
                        <td className='text-start mb-1'>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/${currency.code.toLowerCase()}.png`}
                                alt={`${currency.name} flag`}
                                width={'40%'}
                            />
                             <span className="text-mainCurrency"> {currency.name.toUpperCase() === "PESO ARGENTINO" ? "PESO" : currency.name.toUpperCase() === "DÓLAR USA" ? "DÓLAR" :  currency.name.toUpperCase()}</span>
                        </td>
                        <td className="text-mainCurrency-Value  text-end">{currency.buy}</td>
                        <td>
                            <span></span>
                        </td>
                        <td className="text-mainCurrency-Value  text-end">{currency.sell}</td>
                    </tr>
                ))}
            </tbody>
        </table>
</div>

)
};
export default MainCurrencies;